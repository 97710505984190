.container {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    display: flex;
    min-height: 150px;
}

.vTextContainer {
    position: relative;
    color: #909090;
    letter-spacing: 0.06em;
    font-size: 1.1em;
    width: 30px;
}
.vText {
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}

.metricsTable {
    width: 100%;
    color: black;
    border-collapse: collapse;
}

.metricsTable td {
    border: 1px solid #dfdfdf;
    font-size: 0.8rem;
    padding: 2px;
    padding-left: 5px;
}

.valueCell {
    width: 50px;
    text-align: center;
}
