.summaryTable {
    width: 100%;
}

.summaryTable th {
    font-weight: normal;
    text-align: center;
}

.summaryTable td {
    border-bottom: 1px solid #aaa;
    border-top: 1px solid #aaa;
    text-align: center;
}

.summaryTable td,
.summaryTable th {
    font-size: 0.8em;
}
