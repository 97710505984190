svg path.workSleepLine {
    fill: none;
    stroke: #6a9cea;
    stroke-width: 2px;
}

svg path.sleepLine {
    fill: none;
    stroke: #6a9cea;
    stroke-width: 0.75px;
}

svg path.awakeZoomLine {
    fill: none;
    stroke: #c0c0c0;
}

svg path.awakeLine {
    fill: none;
    stroke: #202020;
    stroke-width: 0.75px;
}

svg path.crewingLine {
    fill: none;
    stroke: black;
    stroke-width: 2px;
}

svg path.notCrewingLine {
    fill: none;
    stroke: grey;
    stroke-width: 2px;
}

svg path.markerLine {
    fill: none;
    stroke: mediumorchid;
    stroke-width: 2px;
}

svg path.criticalMarkerLine {
    fill: none;
    stroke: purple;
    stroke-width: 2px;
}

svg path.redLine {
    fill: none;
    stroke: red;
    stroke-width: 1px;
}

svg path.redLineOrigin {
    fill: none;
    stroke: red;
    stroke-width: 0.5px;
    stroke-dasharray: 5, 5;
}

svg path.allLine {
    fill: none;
    stroke: #cbcaca;
    stroke-width: 1px;
}

svg path.zoomLineThin {
    stroke-width: 1px;
}
svg path.zoomLineMed {
    stroke-width: 2px;
}
svg path.zoomLineThick {
    stroke-width: 3px;
}

svg path.sleepBar {
    fill: none;
    stroke: blue;
    stroke-width: 10px;
}

svg path.awakeBar {
    fill: none;
    stroke: grey;
    stroke-width: 10px;
}

svg path.crewingBar {
    fill: none;
    stroke: black;
    stroke-width: 10px;
}

svg rect.awakeRect {
    fill: #d0d0d0;
    stroke: #d0d0d0;
    cursor: move;
}

svg rect.sleepRect {
    fill: #6a9cea;
    stroke: #6a9cea;
    /*cursor: move;*/
}

svg rect.criticalAutoMarkerRect {
    fill: purple;
    stroke: purple;
    /*cursor: move;*/
}

svg rect.autoMarkerRect {
    fill: mediumorchid;
    stroke: mediumorchid;
    /*cursor: move;*/
}

svg rect.explicitSleepRect,
svg rect.editableSleepRect {
    fill: rgb(37, 73, 131);
    stroke: rgb(37, 73, 131);
    cursor: ew-resize;
}

svg rect.daylightBarRect {
    opacity: 1;
}

svg rect.darkRect {
    fill: #dfe1e6;
    stroke: #dfe1e6;
}

.shift-pressed svg rect.darkRect:hover,
.shift-pressed svg rect.twilightRect:hover,
.shift-pressed svg rect.daylightRect:hover {
    cursor: pointer;
}

svg rect.twilightRect {
    fill: #f7f1c4;
    stroke: #f7f1c4;
}

svg rect.daylightRect,
svg rect.lightRect {
    fill: #fff;
    stroke: #fff;
}

svg rect.validationErrorRect {
    fill: #ff6666 !important;
    stroke: #ff6666 !important;
}

svg rect.crewingRect {
    fill: black;
    stroke: black;
    cursor: ew-resize;
}

svg rect.notCrewingRect {
    fill: grey;
    stroke: #606060;
    cursor: ew-resize;
}

svg rect.criticalMarkerRect {
    fill: purple;
    stroke: purple;
    cursor: ew-resize;
}

svg rect.markerRect {
    fill: mediumorchid;
    stroke: mediumorchid;
    cursor: ew-resize;
}

svg rect.notCrewingRect,
svg line.crewingLeftEdgeLine,
svg line.notCrewingLeftEdgeLine,
svg line.criticalMarkerLeftEdgeLine,
svg line.markerLeftEdgeLine,
svg line.sleepLeftEdgeLine,
svg line.explicitSleepLeftEdgeLine,
svg line.criticalAutoMarkerLeftEdgeLine svg line.autoMarkerLeftEdgeLine {
    stroke-width: 1px;
    shape-rendering: geometricPrecision;
}

svg line.crewingLeftEdgeLine {
    stroke: #606060;
}

svg line.notCrewingLeftEdgeLine {
    stroke: #606060;
}

svg line.criticalMarkerLeftEdgeLine {
    stroke: mediumorchid;
}

svg line.markerLeftEdgeLine {
    stroke: purple;
}

svg line.criticalAutoMarkerLeftEdgeLine {
    stroke: mediumorchid;
}

svg line.autoMarkerLeftEdgeLine {
    stroke: purple;
}

svg line.sleepLeftEdgeLine {
    stroke: #3e7ce3;
}

svg line.explicitSleepLeftEdgeLine {
    stroke: #6a9cea;
}

svg rect.eventDragEnd {
    opacity: 0;
    cursor: col-resize;
    fill: #606060; /* fill required for cursor */
}

svg path.minLine {
    fill: none;
    stroke: grey;
    stroke-width: 1px;
}

svg path.maxLine {
    fill: none;
    stroke: grey;
    stroke-width: 1px;
}

svg path.targetLine {
    fill: none;
    stroke: grey;
    stroke-dasharray: 5, 5;
    stroke-width: 1px;
}

svg path.highArea {
    fill: rgb(179, 255, 175);
    stroke: grey;
}

svg path.mediumArea {
    fill: rgb(254, 255, 100);
    stroke: grey;
}

svg path.mediumLowArea {
    fill: rgb(252, 204, 204);
    stroke: grey;
}

svg area.lowArea {
    fill: rgb(254, 176, 179);
    stroke: grey;
}

svg path.lowArea {
    fill: rgb(254, 176, 179);
    stroke: grey;
}

.xAxisMajor line,
.xAxisMajor path {
    fill: none;
    stroke: black;
    stroke-width: 1px;
    shape-rendering: crispEdges;
}

.xAxisZoomMajor line,
.xAxisZoomMajor path {
    fill: none;
    stroke: #606060;
    stroke-width: 0.5px;
}

.xAxisMinor line,
.xAxisMinor path {
    fill: none;
    stroke: lightgrey;
    stroke-width: 0.5px;
    shape-rendering: geometricPrecision;
}

/* DST line that appears on the graph */
line.js-graph-vertical-dst-lines {
    stroke: orange;
    stroke-width: 1px;
}

line.inspector-dashed-line {
    stroke: black;
    stroke-width: 3px;
    stroke-dasharray: 5 5;
}

rect.inspector-line-container {
    fill: transparent;
    cursor: ew-resize;
}

text.inspector-line-number-circle-text {
    font: 24px sans-serif;
    font-weight: 700;
    fill: #555;
    cursor: ew-resize;
}

circle.inspector-line-number-circle {
    fill: rgba(255, 255, 255, 0.5);
    stroke: #ddd;
    cursor: ew-resize;
}

circle.inspector-line-number-circle-close-circle {
    fill: rgba(255, 255, 255, 0.8);
    stroke: #999;
    cursor: pointer;
}

line.inspector-line-number-circle-close-circle-x {
    fill: white;
    stroke: #666;
    cursor: pointer;
}

.xAxisZoomMinor line,
.xAxisZoomMinor path {
    fill: none;
    stroke: lightgrey;
    stroke-width: 1px;
}

.xAxis text {
    fill: black;
}

svg .xAxisMajor text {
    fill: #a6a6a6;
}
svg .xAxisMinor text {
    fill: #a6a6a6;
}
svg .xAxisDate text {
    fill: black;
}

.xAxisDate line,
.xAxisDate path {
    fill: none;
    stroke: #939393;
    stroke-width: 1px;
    shape-rendering: geometricPrecision;
}

.axisHidden .domain {
    opacity: 0;
}

svg .xAxisLabel text {
    fill: black;
}

svg text.key-labels {
    fill: black;
}

.key rect {
    fill: none;
    stroke: black;
    stroke-width: 0.5px;
}

.yAxis line,
.yAxis path {
    fill: none;
    stroke: black;
    stroke-width: 1px;
    shape-rendering: geometricPrecision;
}

.yAxis path {
    fill: none;
    stroke: black;
    stroke-width: 0.5px;
    shape-rendering: crispEdges;
}

.yAxis text {
    fill: #a6a6a6;
}

.yAxis .label {
    fill: black;
    font-weight: 400;
    text-transform: uppercase;
}

.yRightAxis text {
    fill: red;
}

.title text {
    fill: black;
}

svg path.Incident {
    fill: red;
}

svg path.Event {
    fill: grey;
}

svg path.Waypoint {
    fill: grey;
}

svg line.Incident {
    fill: none;
    stroke: red;
    stroke-width: 1.5px;
    stroke-dasharray: 5, 5;
    shape-rendering: crispEdges;
}

svg line.Waypoint {
    fill: none;
    stroke: grey;
    stroke-width: 1.5px;
    stroke-dasharray: 5, 5;
    shape-rendering: crispEdges;
}

svg line.Event {
    fill: none;
    stroke: grey;
    stroke-width: 1px;
    stroke-dasharray: 5, 5;
    shape-rendering: crispEdges;
}

svg rect.graphBorder {
    stroke: black;
    stroke-width: 0.5px;
    fill: none;
}

svg rect.zoombar-slider {
    fill: #505050;
    cursor: col-resize;
}

/* avoid interrupting clicks and drags if the user
clicks on the effectiveness line in the zoombar */
svg g.zoombar-group path,
svg rect.resizing-indicator-rectangle {
    pointer-events: none;
}

svg text {
    font-size: 14px;
}

svg text.graph-effectiveness-label {
    font-size: 11px;
}

.both-view svg text {
    font-size: 12.5px;
}

.both-view .xAxisMinor text {
    font-size: 10px;
}

.js-waypoint-text {
    font-size: 11px;
}

.both-view svg text.graph-effectiveness-label {
    font-size: 11px;
}
