/* NB: these colors are duplicated in a few css files unfortunately */

.typeColor {
    position: relative;
    top: 0px;
    display: inline-block;
    width: 10px;
    height: 10px;
    bottom: 20px;
    margin-right: 2px;
}

.typeColor_crewing {
    background-color: black;
}
.typeColor_notCrewing {
    background-color: grey;
}

.typeColor_criticalMarker {
    background-color: purple;
}

.typeColor_marker {
    background-color: mediumorchid;
}

.typeColor_criticalAutoMarker {
    background-color: purple;
}

.typeColor_autoMarker {
    background-color: mediumorchid;
}

.typeColor_sleep {
    background-color: #6a9cea;
}

.typeColor_explicitSleep {
    background-color: rgb(37, 73, 131);
}
