.toolbarButtonWrapper {
    display: inline-block;
    margin-left: 4px;
}

.toolbarButton,
.toolbarButton button {
    min-width: 70px;
    border-radius: 4px;
    font-size: 14px;
    padding-bottom: 0;
    padding-top: 0;
}

.toolbarButton {
    margin-left: 6px;
}
