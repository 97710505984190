.navbarOverride {
    padding-bottom: 0px;
    padding-top: 0px;
}

.navbarBrandOverride {
    padding-bottom: 0px;
    padding-top: 0.6rem;
}

:global(.nav-link) {
    padding-left: 15px !important; /* extra space between navbar links */
    padding-right: 15px !important; /* extra space between navbar links */
    white-space: nowrap;
}

.sfLogo {
    background: url(assets/SFLogoWhiteTextNoTag.svg) no-repeat top left;
    background-size: contain;
    display: block;
    height: 67px;
    width: 150px;
}
