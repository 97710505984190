/* 'important' needed to to override the kendo styles */
.cell {
    text-align: left !important;
}

.labelButtonNonInteractive:hover {
    text-decoration: none !important;
    cursor: default;
}

.transparentButton {
    padding-left: 4px;
}
.transparentButton:focus {
    outline: none;
}

.labelCrewing {
    font-weight: bold;
}
