body {
    overflow: hidden;
    font-size: 16px;
}

svg text,
.k-grid-content td {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* when using a button that shouldn't look like a button */
.transparentButton {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-weight: unset;
}

.transparentButton:focus {
    outline: 1px solid #ccc;
}

.linkStyle {
    color: #007bff !important;
    margin-bottom: 0;
}

.linkStyle:hover {
    text-decoration: underline !important;
    color: #0056b3 !important;
    cursor: pointer !important;
}

.reportsInsightsDashboardContainingRowPrintPreview,
.reportsInsightsDashboardContainingRow {
    margin-left: 4px !important;
    height: calc(100vh - 170px) !important;
    overflow-y: scroll;
}

.reportsInsightsDashboardContainingRowPrintPreview {
    overflow: unset;
    /* the bootstrap layout row is flex by default; this prevents break-inside from working on print mode */
    display: block;
}

.reportsInsightsDashboardContainingRowForDesktop {
    height: calc(100vh - 70px) !important;
}

.reportsInsightsDashboardThumbnail {
    display: block;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    padding-left: 10px;
    text-decoration: none;
}
.reportsInsightsDashboardThumbnail:hover {
    text-decoration: none;
}

.reportsInsightsDashboardThumbnail:focus {
    outline: 1px solid #ccc;
}

.printPreview.reportsInsightsDashboardThumbnail {
    pointer-events: none !important;
    width: 1000px !important;
}

/* dialog tabs dont show as blue links */
nav.nav-tabs a.nav-item.nav-link {
    color: unset;
}

.scrollableModalBody {
    max-height: calc(100vh - 500px);
    overflow-y: auto;
}

.DutiesListDialog {
    width: 1100px;
    max-width: none;
}

.ShiftsDialog {
    width: 900px;
    max-width: none;
}

.DialogMediumWidth {
    width: 800px;
    max-width: none;
}

.DialogSmallWidth {
    width: 600px;
    max-width: none;
}

.blinkingGreenButton {
    animation-name: blinking-background;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

/* the default background of a button in bootstrap used to be this color, then it seemed to change to transparent; we were using btn-white, which turns out wasn't actually a thing (it was just using bootstrap default). But we want to keep the same appearance, so we are now styling btn-white to match the old bootstrap default background */
.btn-white:disabled {
    background-color: rgba(239, 239, 239, 0.3);
}
.btn-white {
    background-color: rgb(239, 239, 239);
}

@keyframes blinking-background {
    50% {
        background: rgb(168, 211, 168);
    }
}

.blinkingGreenIcon {
    animation-name: blinking-color;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

@keyframes blinking-color {
    50% {
        color: rgb(168, 211, 168);
    }
}

.pointerCursor:hover {
    cursor: pointer;
}

@media print {
    .noPrint {
        display: none;
    }
    /* Hide the Kendo Grid Filter Row in print */
    .k-filter-row {
        display: none;
    }
}

/* This will override boostrap default modal to be scrollable. */
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 250px);
}

.print-preview-grid .k-filter-row {
    display: none;
}

/* for use with react-tooltip which is slightly transparent by default */
/* https://github.com/wwayne/react-tooltip/issues/277#issuecomment-617010033 */
.__react_component_tooltip.show {
    opacity: 1 !important;
}

.k-i-add:before,
.k-i-plus:before,
.k-plus:before {
    content: '\E014';
}
.k-i-kpi-trend-equal:before,
.k-i-minus:before,
.k-minus:before {
    content: '\E015';
}
.k-chart-tooltip-wrapper {
    margin-top: -2rem;
    white-space: nowrap;
}
/* Global style to allow background colors when printing. Taken from v5 */
body {
    -webkit-print-color-adjust: exact !important;
}
