/* Overall page must flex horizontally (row) */
.loginpage {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 330px;
    max-width: 90%;
    margin: 0 auto;
}

.login-form input[type='text'] {
    margin-bottom: 4px;
}

/* background images */
:global(.splash-background) {
    /* javascript overrides the opacity as it rotates through the images */
    opacity: 0;
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100vh;
    z-index: -1;
    transition: opacity 2.5s;
    transition-timing-function: ease-in-out;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* text over the image */
.login-right-img-text-container {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.login-right-img {
    flex: 0.5;
    height: 100vh;
    background: #111;
    /* background color needs to be behind the image */
    z-index: -2;
}

.loginformcontainer {
    flex: 0.5;
}

/* hide the image when the screen is horizontally narrowed */
@media (max-width: 860px) {
    .loginformcontainer {
        flex: 1;
    }

    .login-right-img {
        flex: 0;
    }

    .login-right-img-text-container {
        display: none;
    }

    :global(.splash-background) {
        background: none !important;
    }
}

@media (max-height: 400px) {
    /* bootstrap uses important, so we need this to override unfortunately */
    /* Seems there aren't vertically responsive classes in bootstrap ? */
    .login-form img {
        margin: 2px !important;
    }
    .login-form button {
        margin: 2px !important;
    }
    .login-form a {
        margin: 0 !important;
    }
}
