.controlCircle {
    border: 1px solid #aaa;
    min-width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
