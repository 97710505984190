.container {
    color: #333;
}

.title {
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 10px;
}
