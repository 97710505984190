.container {
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.formContainer {
    width: 350px;
}

.logo {
    width: 330px;
    margin-bottom: 30px;
}

.password1 {
    margin-bottom: 4px;
}
