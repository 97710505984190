.button {
    border: 1px solid #ccc;
    min-width: 32px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
}

.button button {
    padding: 0;
}
