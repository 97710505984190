.container {
    position: relative;
}

.suggestionsContainer {
    display: none;
}

.suggestionsContainerOpen_EventDialog,
.suggestionsContainerOpen {
    display: block;
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid #aaa;
    background-color: #fff;
    font-size: 14px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.suggestionsContainerOpen_EventDialog {
    /* aligned with other form elements */
    width: 480px;
}

.suggestionsList {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.suggestion {
    cursor: pointer;
    padding: 4px 10px;
    border-bottom: 1px solid #ddd;
}
.suggestionHighlighted {
    background-color: #ddd;
}
