/* Override react-toastify styles here.  See https://fkhadra.github.io/react-toastify/how-to-style for more details */

/** Used to define container behavior: width, position: fixed etc... **/
/* .Toastify__toast-container {
} */

/** Used to define the position of the ToastContainer **/
/* .Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
} */

/** Classes for the displayed toast **/
/* .Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
} */

/* bootstrap-style overrides */
.Toastify__toast--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.Toastify__toast--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.Toastify__toast--info {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
.Toastify__toast--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

/* .Toastify__toast-body {
} */

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    color: #3a1d10;
}
/* .Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
} */

/** Classes for the progress bar **/
.Toastify__progress-bar {
    background-color: #99a1bb;
}

/* .Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
} */
