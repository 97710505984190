.cs-card-text {
    color: #505050;
}

.cs-card-text-item {
    width: 100px;
    display: inline-block;
}

.cs-card-text button {
    padding-left: 0;
}

.card {
    border: none;
}

.cardHeader {
    padding: 0;
    background-color: transparent;
    border: none;
}

.cardBody {
    padding: 0;
    border: none;
}
