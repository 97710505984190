/* Turn off the dumb ellipsis on the kendo grid column headings https://www.telerik.com/forums/header-wrapping-height */
/* https://www.telerik.com/forums/kendo-grid---column-headers-titles-remove-ellipsis */
.k-grid .k-grid-header th.k-header .k-link {
    text-overflow: initial;
    padding-left: 4px;
    padding-right: 4px;
}
.k-grid .k-grid-header .k-cell-inner {
    /* white-space: initial; */
    /* border: 1px solid blue; */
    /* width: 100%; */
    /* added margin-right because the cell seems to be extra wide and disappears under the one next to it; they helps center the content */
    /* margin-right: 15px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.k-grid .k-grid-header .k-header .k-link {
    height: auto;
}
.k-grid .k-grid-header .k-header {
    white-space: normal;
}
.k-grid th.k-header {
    font-weight: 500;
}
.k-grid.center-content td {
    text-align: center;
}

th.k-filterable.k-header:not(.customHeader) {
    /* column menu ellipsis */
    /* squeeze the column menu ellipsis over the the right */
    padding-right: 34px;
}

th.k-filterable.k-header.customHeader {
    /* custom grid header (like Metric (Crewing)) */
    padding-right: 18px;
    padding-left: 4px;
}

.customHeader .k-grid-column-menu.k-grid-filter {
    right: 0;
    bottom: -2px;
}

.k-grid-filter .k-i-more-vertical::before {
    /* change the default column menu icon from \e031 (ellipsis) to a filter */
    /* see more icons here https://docs.telerik.com/kendo-ui/styles-and-layout/sass-themes/font-icons */
    content: '\e129';
    color: #888;
}
.activeFilter .k-grid-filter .k-i-more-vertical::before {
    color: blue;
}

/* show underline on hyperlinks in the grid, and hyperlink like elements */
.k-grid a:hover,
label.grid-cell-link-hover:hover,
span.grid-cell-link-hover:hover,
.k-grid .grid-cell-link-hover:hover {
    text-decoration: underline;
    color: #0056b3;
    cursor: pointer;
}
label.grid-cell-link-hover,
span.grid-cell-link-hover,
.k-grid .grid-cell-link-hover {
    color: #007bff;
}

.k-grid td[role='gridcell'] {
    vertical-align: middle;
}

.three-dot-dropdown:after {
    content: '\2807';
}

/* reduce the size of the paging controls on the kendo grid */
.k-pager-wrap {
    max-height: 20px !important;
    font-size: 0.8em !important;
}

.sf-kendo-header-checkbox {
    text-align: center !important;
}

.k-detail-cell {
    background-color: #eee;
}

.k-animation-container {
    z-index: 10000 !important;
}

.k-grid .left-aligned {
    text-align: left !important;
}

.k-grid .center-aligned {
    text-align: center;
}

.k-grid .no-ellipsis {
    text-overflow: clip;
}

.small-kendo-grid td,
.small-kendo-grid th {
    font-size: 0.85em !important;
    color: #444;
}

.small-kendo-grid th .k-grid-column-menu {
    /* column menu ellipsis */
    width: 16px;
    font-size: 1.2em;
    padding-left: 0;
    padding-right: 0;
}

.small-kendo-grid th {
    font-size: 0.8em !important;
    text-align: center !important;
}

.small-kendo-grid thead .k-dropdown-wrap,
.small-kendo-grid thead .k-dropdown-operator .k-select,
.small-kendo-grid thead .k-clear-button-visible {
    height: 24px !important;
    /* width: 24px; */
    margin-right: 2px !important;
}

.small-kendo-grid thead th .k-dateinput {
    font-size: 0.8em !important;
}

.small-kendo-grid thead .k-filtercell-wrapper {
    height: 24px !important;
}
/* remove this section because it will make the header cell icon small and title wil change vertial position when icon display*/
/* .small-kendo-grid thead .k-icon {
    height: 24px !important;
    font-size: 0.8em !important;
} */
.small-kendo-grid thead .k-icon.k-i-arrow-n,
.small-kendo-grid thead .k-icon.k-i-arrow-s {
    height: 12px !important;
}
.small-kendo-grid thead .k-dropdown {
    vertical-align: top !important;
}

.small-kendo-grid thead th input.k-input,
.small-kendo-grid thead th .k-picker-wrap,
.small-kendo-grid thead th .k-input,
.small-kendo-grid thead th .k-numeric-wrap,
.small-kendo-grid thead th .k-dateinput-wrap input,
.small-kendo-grid thead th input.k-textbox {
    height: 24px !important;
}

.small-kendo-grid thead th .k-filtercell-wrapper input {
    padding-left: 4px !important;
}

span.k-column-title {
    white-space: normal;
}

/* splitter used in Report view */
.k-splitter {
    border: none !important;
}

/* Start Kendo custom color picker overrides */
.customColorPicker .k-colorpicker {
    border: 0 !important;
}
.customColorPicker .k-input-inner {
    width: 24px !important;
    padding: 0 !important;
    height: 36px !important;
}

.customColorPicker .k-button {
    /* hide the default dropdown arrow button in favor of the clean look of just clicking directly on the color */
    display: none;
}

.k-colorgradient .k-alpha-slider {
    display: none;
}
/* End Kendo custom color picker overrides */

.k-grid .labelCrewing {
    font-weight: bold;
}

/* date time picker calendard popup size adjustments */
.k-datetime-calendar-wrap .k-calendar-header.k-hstack {
    padding: 0 !important;
}
.k-date-tab.k-datetime-wrap .k-datetime-buttongroup button,
.k-datetime-footer.k-actions button {
    height: 30px !important;
}

.k-calendar-monthview .k-calendar-td {
    height: 25px !important;
}

.k-calendar-monthview .k-calendar-td .k-link {
    height: 25px !important;
}
.k-calendar-infinite .k-calendar-view {
    height: 270px !important;
}

/* end date time picker calendard popup size adjustments */
