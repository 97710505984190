.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.containerPrint {
    composes: containerPrint from '../PatternPanel/PatternPanel.module.css';
}

.clickableChart svg > g > g {
    cursor: pointer;
}

.chartPrint svg > g > g {
    cursor: default;
}
