.modal {
    display: block;
    position: absolute;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.modalContent {
    position: fixed;
    background-color: #fefefe;
    text-align: center;
    padding: 20px;
    min-width: 250px;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadingGif {
    width: 28px;
    height: 28px;
    margin-top: -10px;
    margin-right: 10px;
}

.textContent {
    display: inline;
}
