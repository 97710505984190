.dropdown {
    composes: button from './IconButton.module.css';
    max-width: none;
}

.dropdown button {
    padding-left: 8px;
    padding-right: 8px;
    min-width: 140px;
}
