.bigButton {
    margin-right: 20px;
    min-width: 140px;
}

/* This bypasses the "active" state provided by bootstrap which causes the
button to look like it is in the activate state */
:global(.btn-outline-secondary).bigButton:hover {
    background-color: white;
    color: #555;
}

:global(.btn-secondary).bigButton:hover {
    background-color: #666f77;
}
