.qb-lite .group--header:hover .group--header .group--drag-handler,
.qb-lite .group--header:hover .group--header .group--actions,
.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions {
    opacity: 1;
}

.qb-lite .rule:hover .rule .widget--valuesrc,
.qb-lite .rule:hover .rule .rule--drag-handler,
.qb-lite .rule:hover .rule .rule--header,
.qb-lite .rule:not(:hover) .widget--valuesrc,
.qb-lite .rule:not(:hover) .rule--drag-handler,
.qb-lite .rule:not(:hover) .rule--header {
    opacity: 1;
}

.qb-lite .rule {
    background-color: #eee;
}

.qb-lite button:focus,
.qb-lite button:hover {
    border-color: #aaa;
    color: #555;
}
.qb-lite button svg {
    margin-bottom: 5px;
}

.qb-lite .group {
    border: 1px solid #aaa;
    background-color: white;
}

.qb-lite span.anticon-ellipsis {
    display: none;
}

.qb-lite div.rule--field div.ant-select {
    min-width: 150px;
}

.qb-lite div.ant-input-number {
    min-width: 150px;
}
