.emptySpace {
    width: 10px;
    display: inline-block;
}

.autoEventIcon::before {
    content: 'A';
    display: inline-block;
    margin-right: 2px;
    color: #aaa;
    font-size: 0.9em;
}
