.container {
    margin-bottom: 20px;
}

.eventName {
    margin-bottom: 4px;
}

.eventsWrapper {
    display: flex;
    justify-content: space-between;
}

.eventsWrapper > div {
    width: 47%;
}

.timeLabel {
    display: flex;
}
.timeLabel,
.timeLabel p {
    margin-bottom: 4px;
}

.leftRightArrowIcon {
    margin-left: 5px;
    color: #1e7e34;
    font-size: 1.1em;
}
