.container {
    border: 1px solid #aaa;
    margin-bottom: 10px;
    padding: 10px;
}

.containerPrint {
    width: 1150px;
}

.toolbarSection {
    display: flex;
    justify-content: space-between;
}

.dutyVizPieSection {
    display: flex;
    justify-content: space-between;
}

.dutyVizSection {
    margin: 5px;
    margin-right: 20px;
}

.dutyVizSection {
    flex: 1;
}

.bottomSection {
    margin-top: 30px;
}

@media print {
    .container {
        break-inside: avoid;
    }
}
