.summaryRow {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 5px;
}

.metricTime {
    color: #333;
    font-size: 0.9em;
    font-weight: 500;
    margin: 0;
    margin-left: 10px;
}

.metricsDashboardButton {
    margin-top: 5px;
    border: 1px solid #aaa;
    background-color: white;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    z-index: 9999;
}

.metricsDashboardLineNumber {
    text-align: center;
    color: #333;
    /* cursor: default; */
}
