/* ie11 doesn't support css variables, so we need to duplicate styles from graph.css */

:global(.eventTimelineTooltip) {
    position: absolute;
    opacity: 0.95;
    z-index: 9999;
    font-size: 0.8em;
}

:global(.eventTimelineTooltip) .header {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
}

:global(.eventTimelineTooltip) .normalFont {
    font-weight: normal;
}

:global(.eventTimelineTooltip) .typeColor {
    padding: 0;
    width: 100%;
    height: 30px;
    padding-left: 20px;
    padding-top: 5px;
}

:global(.eventTimelineTooltip) .typeColor_daylight,
:global(.eventTimelineTooltip) .typeColor_dark,
:global(.eventTimelineTooltip) .typeColor_twilight {
    height: 0;
}
:global(.eventTimelineTooltip) .typeColor_crewing {
    background-color: black;
    color: #eee;
}
:global(.eventTimelineTooltip) .typeColor_notCrewing {
    background-color: grey;
    color: #eee;
}

:global(.eventTimelineTooltip) .typeColor_criticalMarker {
    background-color: purple;
    color: #eee;
}

:global(.eventTimelineTooltip) .typeColor_marker {
    background-color: mediumorchid;
    color: #eee;
}

:global(.eventTimelineTooltip) .typeColor_autoMarker {
    background-color: mediumorchid;
    color: #eee;
}

:global(.eventTimelineTooltip) .typeColor_criticalAutoMarker {
    background-color: purple;
    color: #eee;
}

:global(.eventTimelineTooltip) .typeColor_sleep {
    background-color: #6a9cea;
    color: #eee;
}

:global(.eventTimelineTooltip) .typeColor_explicitSleep {
    background-color: rgb(37, 73, 131);
    color: #eee;
}

:global(.eventTimelineTooltip) .bodySection {
    margin-top: 6px;
}

:global(.eventTimelineTooltip) .metricCell {
    display: inline-block;
    text-align: center;
    margin-left: 10px;
    width: 50px;
    margin-top: 4px; /* a little space between the metrics */
    color: black;
}
