.container {
    position: absolute;
    top: 10px;
    right: 25px;
    z-index: 100;
    display: flex;
}

.controlButton:hover {
    cursor: pointer;
}
