.dashboard {
    top: 100px;
    left: 500px;

    width: 240px;
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    cursor: move;
    padding-top: 8px;
    padding-bottom: 12px;
    display: block;
    color: #919190;
    text-align: left;
    background: white;
    box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.25);
    z-index: 999;
}

.dashboardHorizontal {
    width: 465px;
}

.metricsBlocksHorizontal {
    display: flex;
}

.metricsBlocksHorizontal div:nth-child(2) {
    margin-left: 0;
}

.dashboardNumberContainer {
    position: absolute;
    top: 16px;
    right: -14px;

    display: flex;
    width: 100%;
    justify-content: flex-end;
}
