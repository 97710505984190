.tableButton {
    width: 120px;
}

.tableButtonGrab {
    width: 120px;
    cursor: move !important;
}

.eventsTable table {
    /* need border-collapse to set border colors at the tr level */
    border-collapse: collapse;
}
.eventsTable table tr {
    /* invisible border so when we show the drag/drop borders, the table doesn't flicker */
    border-top: 2px solid transparent;
    border-bottom: 1px solid transparent;
}

/* drag/drop borders */
.eventsTable table :global(.dropTargetDutyRow) {
    background-color: rgb(224, 237, 245) !important;
    border-bottom: 2px solid rgb(224, 237, 245) !important;
    border-top: 2px solid rgb(224, 237, 245) !important;
}

.eventsTable table :global(.dropTargetBottomBorderBlue) {
    border-bottom: 2px solid blue !important;
}

.eventsTable table :global(.dropTargetTopBorderBlue) {
    border-top: 2px solid blue !important;
}
