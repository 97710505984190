.container {
    position: relative;
    border-radius: 15%;
    /* right: 5px; */
    width: 50px;
    height: 46px;
    border: 1px solid #555;
    color: #333;
}

.metricValue {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 1.2em;
    font-weight: 600;
}
