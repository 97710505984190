.error {
    background-color: #ea00006e;
}

.formLabel {
    font-weight: 500;
}

.checkBoxContainer {
    padding-left: 15px;
}
