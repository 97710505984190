.container {
    /* position near the top-right of the dashboard */
    position: absolute;
    top: -16px;
    right: -14px;

    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.button {
    color: #777;
}

.container > * {
    /* space between the control buttons */
    margin-left: 3px;
}
